import { state } from ":mods";
import { getTeams } from "../..";
import { Show, createEffect } from "solid-js";

function getTitle(title: string) {
  if (title === undefined || title === null) {
    return undefined;
  }
  title = title.replace("_", " ");
  return title;
}

export default function DescriptionSection() {
  const $teamsList = state.create([]);
  const $db_teams = state.createAsync(getTeams);
  createEffect(() => {
    $teamsList.set($db_teams.value?.data?.[0]?.teamList);
  });
  return (
    <section class="flex flex-col bg#paper items-center">
      <div class="flex flex-col gap-40px justify-center items-center my-80px">
        {$teamsList.value?.reverse().map(({ title, members }) => {
          return (
            <section class="flex flex-col mb-102px justify-center items-center" id="team-row-section">
              <p class="mb-80px font-bold capitalize">{getTitle(title)}</p>

              <div class="flex flex-wrap justify-center items-center gap-75px w-1065px">
                {members?.map((member) => {
                  return (
                    <div class="flex flex-col justify-center items-center gap-40px">
                      <div class="h-261px w-261px bg#p rounded-50% overflow-hidden">
                        <Show when={member.image?.url}>
                          <img
                            // class={"w-full h-full"}
                            src={member.image?.url}
                            width={member.image?.width}
                            height={member.image?.height}
                            alt={member.image?.alt}
                          />
                        </Show>
                      </div>
                      <div class="flex flex-col gap-10px w-300px text-16px font-normal">
                        <p id="member-name" class="text-25px font-bold leading-28px">
                          {member.name}
                        </p>
                        <p id="member-title">{member.designation}</p>
                        <p id="member-bio">{member.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          );
        })}
      </div>
      <hr class="max-w-screen w-screen h-2px bg#p" />
    </section>
  );
}
